import { createMuiTheme } from "@material-ui/core/styles";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face{
      font-family:"Sofia Pro";
      src: url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716108/fonts/sofiaPro_regular/sofiaPro_regular_awpq3e.woff2") format("woff2"),url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716108/fonts/sofiaPro_regular/sofiaPro_regular_c40mov.woff") format("woff");
  }
  @font-face{
      font-family:"Sofia Pro semibold";
      src: url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716109/fonts/sofiaPro_semiBold/sofiaPro_semiBold_me7wd7.woff2") format("woff2"),url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716109/fonts/sofiaPro_semiBold/sofiaPro_semiBold_usdvnh.woff") format("woff");
  }
  @font-face{
      font-family:"Sofia Pro bold";
      src: url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716109/fonts/sofiaPro_bold/sofiaPro_bold_ltxiw7.woff2") format("woff2"),url("https://res.cloudinary.com/hc6vep858/raw/upload/v1588716109/fonts/sofiaPro_bold/sofiaPro_bold_w1wdz6.woff") format("woff");
  }

  body, #react {
    display: block;
    padding: 0;
    margin: 0;  
    font-family:"Sofia Pro";
    background-color: #EFEFEF;
    
  }
  *{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
      display: none;
  }
  a {
    text-decoration: none;
    color: #00A8C4;
  }
`;

export const colors = {
  primary: "#00A8C4",
  secondary: "#FFFFFF",
  black: "#000000",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.secondary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: "#2E2E2E",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1640,
    },
  },
  typography: {
    fontSize: 8,
    htmlFontSize: 8,
    fontFamily: "Sofia Pro",
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: "24px",
      },
    },
    MuiAccordion: {
      root: {
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
          borderBottom: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
